import { createRouter, createWebHashHistory } from 'vue-router'
import { createApp } from 'vue'

import Login from './login.vue'
import { VueReCaptcha } from "vue-recaptcha-v3";

/*
const routes = [
    { path: '/', name:'Root', component: Index },
    { path: '/game', name:'Game', component: Game },
]

const router = createRouter({
    history: true,//createWebHashHistory(),
    routes: routes,
})
*/
const app = createApp(Login).use(VueReCaptcha, { siteKey: "6LdougEgAAAAAMrUiIEoJOVargfJ2AbrZ5NNDYQF", loaderOptions: {useRecaptchaNet: true} })

//app.use(VueNextSelect);
//app.use(router)
app.mount('#main')
